import { extend } from 'vee-validate';
import { required, email, min_value, max, integer } from 'vee-validate/dist/rules'
import { regex } from "vee-validate/dist/rules";

extend('email', email);
extend('required', required);
extend('min_value', min_value);
extend('regex', regex);
extend('max', max);
extend('integer', integer);

extend('certno', {
  validate (value) {
    return /^[A-Z]G?(\d{5})?$/.test(value)
  },
});
extend('zipcode', {
  validate (value) {
    return /^\d{5}(-\d{4})?$/.test(value)
  },
});
