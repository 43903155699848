<template>
  <div id="app">
    <b-modal id="modal-update" ok-title="Update Portal" title="Update Available" @ok=refreshApp>
      <p class="text-center">
        An update to the MS Public Water System Portal is available.
    </p>
    </b-modal>
    <b-modal id="modal-browser-check" ok-only title="Browser Check">
      <p class="text-center">
        Your web browser, Internet Explorer, is obsolete and may not work properly with this application.  Please use a modern web browser such as Edge, Chromium, Firefox, Safari, or Opera.
    </p>
    </b-modal>
    <b-container
      id="main-container"
      fluid
      class="main-container p-0 min-vh-100 d-flex flex-column"
    >
      <b-row no-gutters>
        <b-col>
          <b-navbar
            id="navigation"
            toggleable="lg"
            type="dark"
            variant="primary"
            class="shadow navbar-slide-nav"
          >
            <b-navbar-brand to="/"
              >MS Public Water System Portal</b-navbar-brand
            >

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item to="/">Main</b-nav-item>
                <b-nav-item to="/help">Help</b-nav-item>
                <b-nav-item to="/about">About</b-nav-item>

              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                  <!-- Using 'button-content' slot -->
                  <template v-slot:button-content>
                    <em>{{ username }}<span v-if="signed_in">,
                        PWS: {{ $store.getters.pws_id }} - {{ $store.getters.pws_name }}</span></em>
                  </template>
                  <b-dropdown-item to="signin" v-if="!signed_in"
                    >Sign In</b-dropdown-item
                  >
                  <b-dropdown-item to="signout" v-else
                    >Sign Out</b-dropdown-item
                  >
                  <b-dropdown-item v-if="can_switch_account" @click="switchAccount">Switch Account</b-dropdown-item>
                  <b-dropdown-item to="change-water-system">Change Water System</b-dropdown-item>
                  <b-dropdown-item to="settings">Settings</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row no-gutters class="mb-auto" id="content-row">
        <b-col>
          <b-container id="routed-content" fluid="md" class="routed-content p-2 p-lg-4">
            <router-view @updateUsername="setPwsLoginName" />
          </b-container>
        </b-col>
      </b-row>
      <b-row no-gutters align-v="end">
        <b-col>
          <footer class="page-footer bg-light text-center small">
            <div class="footer-copyright py-3">
              &copy; 2020-{{current_year}} | MS PWS Portal - Version: {{ vue_app_version }}
            </div>
          </footer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import * as idb from "idb-keyval"
import { SessionMixin } from './mixins'

export default {
  mixins: [SessionMixin],
  beforeCreate() {
    this.$router.push({ name: "LoadUserData" })
  },
  created() {
    this.vue_app_version = process.env.VERSION
    // software udpate
    console.log('add listener for swUpdated')
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      console.log('controllerchange event')
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      console.log('reloading')
      window.location.reload()
    })
  },
  async mounted() {
    console.log("App script mounted.")
    if (this.$browserDetect.isIE) {
      this.$bvModal.show('modal-browser-check')
    }
    //await this.Ping()
    //await this.Ping()
    //await this.Ping()
  },
  data() {
    return {
      pws_user_id: "Not Signed In",
      vue_app_version: "",
      // for update
      refreshing: false,
      registration: null,
      updateExists: false,
      error_message: null,
      current_year: new Date().getFullYear()
    }
  },
  computed: {
    can_switch_account() {
      let ret = false
      if (this.$store.state.switch_user) {
        ret = true
      }
      return ret
    },
    signed_in() {
      let ret = false
      if (this.$store.state.signed_in) {
        ret = true
      }
      return ret
    },
    role() {
      return this.$store.state.role
    },
    username() {
      let name = "Not Signed In"
      if (this.$store.state.signed_in != false) {
        name = this.$store.state.user.first_name
        name = name + ' ' + this.$store.state.user.last_name
      }
      return name
    },
  },
  methods: {
    refreshApp() {
      console.log('called refreshApp')
      this.updateExists = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        // Send message to SW to skip the waiting and activate the new SW
        console.log('SKIP_WAITING')
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    updateAvailable(event) {
      console.log('update available event')
      this.registration = event.detail
      this.updateExists = true
      this.$bvModal.show('modal-update')
    },
    async switchAccount() {
      //const user = this.$store.state.user
      //const key = user.user_id + '_identity'
      //await idb.del(key)
      await this.$store.commit('set_signed_in', false)
      this.$router.push({ name: "LoadUserData" })
    },
    setPwsLoginName(pws_user_id) {
      console.log("called setPwsLoginName")
      if (pws_user_id) {
        this.pws_user_id = pws_user_id
      } else {
        this.pws_user_id = "Not Signed In"
      }
    },
    //async Ping() {
    //  console.log("Pinging")
    //  const path = process.env.VUE_APP_API_URI + "/ping"
    //  try {
    //    const res = await this.axios.get(path, { withCredentials: true }) 
    //    console.log(res)
    //  } catch(error) {
    //    console.error(error)
    //  }
    //},
    // Duped in Signin - Need to Refactor
    async getPWS() {
      console.log("Fetching Water Systems")
      const path = process.env.VUE_APP_API_URI + "/get-pws-for-user"
      try {
        const res = await this.axios.get(path, { withCredentials: true }) 
        console.log(res)
        if (res.data.water_systems) {
          console.log('saving water system list')
          console.log(res.data.water_systems)
          this.$store.commit("set_roles_water_systems", res.data.water_systems)
        } else {
          console.log("no water systems")
        }
      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching Water Systems.  Trying reloading page.'
      }
    },
    async setDefaultPWS() {
      if (this.signed_in) {
        const user_id = this.$store.state.user.user_id
        const pws_key = user_id + '_pws'
        const pws = await idb.get(pws_key)
        let df_pws = pws ? pws : this.$store.getters.default_pws
        console.log('df_pws: ' + df_pws)
        this.$store.commit('set_pws', df_pws)
        this.$store.commit('set_pws_id', df_pws.pws_id)
      }
    },
    //async getSession() {
    //  console.log("checking session")
    //  const path = process.env.VUE_APP_API_URI + "/getsession"
    //  try {
    //    const res = await this.axios.get(path, { withCredentials: true }) 
    //    console.log(res)
    //    if (res.data.user) {
    //      this.$store.commit("set_user", res.data.user)
    //      this.$store.commit("set_role", res.data.user.roles[0])
    //      this.$store.commit("set_signed_in", true)
    //      // set default PWS
    //    } else {
    //      console.log("session was empty")
    //    }
    //  } catch(error) {
    //    console.error(error)
    //    this.error_message = 'Error fetching session.  Trying reloading page.'
    //  }
    //},
    async checkSigningKey() {
      if (this.$store.state.user) {
        const user_id = this.$store.state.user.user_id
        const key_name = user_id + '_signing_key'
        const signing_key = await idb.get(key_name)
        if (! signing_key) {
          console.log('generating Key')
          try {
            const wrapped_key = await this.generateKey(key_name)
            await idb.set(key_name, wrapped_key)
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    async generateKey() {
      const key = await window.crypto.subtle.generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256" //can be "P-256", "P-384", or "P-521"
          },
          false, //whether the key is extractable (i.e. can be used in exportKey)
          ["sign", "verify"] //can be any combination of "deriveKey" and "deriveBits"
      )
      const current_datetime = new Date()
      const formatted_date =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1) +
        "-" +
        current_datetime.getDate() +
        " " +
        current_datetime.getHours() +
        ":" +
        current_datetime.getMinutes() +
        ":" +
        current_datetime.getSeconds()
      const wrapped_key = {
        //name: this.signame,
        dt_created: formatted_date,
        key: key
      }
      return wrapped_key
    },
  }
}
</script>
<style scoped>
.navbar-brand {
  font-size: large;
}
</style>
