import * as idb from "idb-keyval"

export var SessionMixin =  {

  methods: {
    async getSigKey() {
      const user_id = this.$store.state.user.user_id
      const keyname = user_id + '_signing_key'
      return await idb.get(keyname)
    }
  },

  computed: {
    signed_in() {
      let ret = false
      if (this.$store.state.signed_in) {
        ret = true
      }
      return ret
    },
    role() {
      return this.$store.state.role
    },
    username() {
      let name = "Not Signed In"
      if (this.$store.state.signed_in != false) {
        name = this.$store.state.user.first_name
        name = name + ' ' + this.$store.state.user.last_name
      }
      return name
    },
    pws_id: function() {
      return this.$store.state.pws.pws_id
    },
    pws_name: function() {
      return this.$store.state.pws.pws_name
    },
  }
}

