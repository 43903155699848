<template>
  <div class="home text-left mb-4">
    <b-alert variant="success" v-model="show_signin_alert" dismissible>
      You have been signed in for the Public Water System {{ pws_id }} in the
      role {{ role }}.
    </b-alert>
    <!--
    <p>
      This portal enables information sharing between Mississippi Public Water
      Systems and the Mississippi State Department of Health, Bureau of Public Water
        Supply. Usage of this portal is intended only for authorized users of
      Mississippi Public Water Systems.
    </p>
    -->
    <b-container fluid class="p-0">
      <h3>Applications</h3>
      <b-row class="mt-3">
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="card-list" />
              Public Water System Reports (Annual Reports)
          </b-button>
           <b-button v-else class="my-1" block size="lg" variant="primary" to="annual-report">
             <b-icon icon="card-list" />
              Public Water System Reports (Annual Reports)
            </b-button>
        </b-col>
        <b-col md>
            <b-button class="my-1" block size="lg" variant="primary" to="ccr">
              <b-icon icon="newspaper" />
              Consumer Confidence Reports
            </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="map" />
            RTCR Sample Site Plans
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="rtcr">
            <b-icon icon="map" />
            RTCR Sample Site Plans
          </b-button>
        </b-col>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="people" />
            Board Members
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="board-members">
            <b-icon icon="people" />
            Board Members
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="droplet" />
            MPHL Sample Results
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="sample-results">
            <b-icon icon="droplet" />
            MPHL Sample Results
          </b-button>
        </b-col>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            E-Mail Tester
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="email-test">
            E-Mail Tester
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
           <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="journal-check" />
              Surveys
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="surveys">
            <b-icon icon="journal-check" />
              Surveys
          </b-button>
       </b-col>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="exclamation-triangle" />
            Emergency Notice
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="emergency">
            <b-icon icon="exclamation-triangle" />
              Emergency Notice
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="droplet" />
              Lead and Copper Sampling and Reporting
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="lead-results">
            <b-icon icon="droplet" />
              Lead and Copper Sampling and Reporting
          </b-button>
        </b-col>
        <b-col md>
           <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="card-checklist" />
              Lead Service Line Inventory
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="lead-inventory">
            <b-icon icon="card-checklist" />
              Lead Service Line Inventory
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="archive" />
              File Cabinet
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="pws-files">
            <b-icon icon="archive" />
              File Cabinet
          </b-button>
        </b-col>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            <b-icon icon="inbox-fill" />
            Messages
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="messages">
            <b-icon icon="inbox-fill" />
            Messages
            <b-badge variant="light">{{message_count}}</b-badge>
          </b-button>
        </b-col>
      </b-row>

      <!--
      <b-row>
        <b-col md>
          <b-button v-if="!signed_in" v-b-modal.sign-in-modal class="my-1" block size="lg" variant="primary">
            Sample Schedules
          </b-button>
          <b-button v-else class="my-1" block size="lg" variant="primary" to="sample-schedules">
            Sample Schedules
          </b-button>
        </b-col>
        <b-col md>
        </b-col>
      </b-row>
      -->

    </b-container>

    <b-container fluid class="mt-4 p-0">
    <h3>Additional Applications (External Websites)</h3>
      <p>These applications are available on other websites.</p>
      <b-row>
        <b-col md>
          <b-button class="my-1" block size="lg" variant="outline-primary" href="https://apps.msdh.ms.gov/DWW/">
              Drinking Water Watch
          </b-button>
        </b-col>
        <b-col md>
          <b-button class="my-1" block size="lg" variant="outline-primary" href="https://samples.mswater.us">
            TCR Sample Notification App
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-button class="my-1" block size="lg" variant="outline-primary" target="_blank" href="https://www.msdhwo.webapps.ms.gov/contactinformation/contactinfologin.aspx">
            LARS (Licensing &amp; Reporting System) LOGIN
          </b-button>
        </b-col>
      </b-row>

    </b-container>
    <div class="mt-5 text-center">
    <b-link to="help">Need Help?</b-link>
    </div>

    <b-modal id="sign-in-modal" title="Sign In Required" @ok="navToSignIn">
      <p>You need to sign in before accessing this application.</p>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      show_signin_alert: false,
      message_count: 0
    }
  },
  mounted() {
    //this.getSession();
  },
  created() {
    console.log('Home: signed_in: ' + this.signed_in)
    console.log('Home: show_alert: ' + this.$store.state.signed_in_alert_shown)
    if (!this.$store.state.signed_in_alert_shown && this.signed_in) {
      this.$store.commit("set_signed_in_alert_shown", true)
      this.show_signin_alert = true
    }
    if (this.signed_in) {
      this.getMessageCount()
    }
  },
  computed: {
    pws_id() {
      return this.$store.state.pws_id
    },
    role() {
      return this.$store.state.role
    },
    signed_in() {
      return this.$store.state.signed_in
    },
    store_counter() {
      return this.$store.state.counter
    }
  },
  methods: {
    getMessageCount() {
      const path = process.env.VUE_APP_API_URI + "/user-message-count"
      const params = {
        message_status: '',
      }
      this.axios
        .get(path, params)
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.message_count = res.data.message_count
          }
        })
    },
    getSession() {
      console.log("checking session")
      const path = process.env.VUE_APP_API_URI + "/getsession"
      this.axios
        .get(path, { withCredentials: true })
        .then((res) => {
          console.log(res)
          if (res.data.pws_id) {
            //this.pws_user_id = res.data.pws_id;
            this.$store.commit("set_pws_id", res.data.pws_id)
            this.$store.commit("set_role", res.data.role)
            this.$store.commit("set_signed_in", true)
            //this.$router.push({ name: 'Home'})
          } else {
            console.log("not logged in")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    navToSignIn() {
      this.$router.push({ path: '/signin' })
    }
  }
}
</script>
<style scoped>
</style>
