import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin, FormPlugin } from 'bootstrap-vue'
import { ProgressPlugin } from 'bootstrap-vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
//import VuePersist from 'vue-persist'
import { store } from './store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import browserDetect from 'vue-browser-detect-plugin';
import './validate.js';

axios.defaults.withCredentials = true;

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Progress Bar
Vue.use(ProgressPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
//Vue.use(FormPlugin)
Vue.use(FormPlugin)
//Axios
Vue.use(VueAxios, axios)
//Persist
//Vue.use(VuePersist, {'expiration':86400000})
//Vee-Validate
Vue.use(browserDetect)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
