import Vue from 'vue'
import Vuex from 'vuex'

import * as idb from "idb-keyval"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: null,
    roles_water_systems: null,
    signed_in: false,
    csrf_token: null,
    signed_in_alert_shown: false,
    pws: null,
    pws_id: null,
    role: null,
    counter: 0,
    switch_user: false,
  },
  mutations: {
    set_signed_in(state, signed_in) {
      state.signed_in = signed_in;
      if (! signed_in) {
        state.pws = null
        state.pws_id = null
        state.water_systems = null
        state.role = null
        const user = state.user
        state.user = null
        state.switch_user = false
        idb.del(user.user_id + '_pws').then(() => {
          console.log('deleted pws')
        })
        idb.del(user.user_id + '_identity').then(() => {
          console.log('deleted identity')
        })
        idb.del('pws_id')
        idb.del('pws_name')
      }
    },
    set_user(state, user) {
      state.user = user
    },
    set_roles_water_systems(state, roles_water_systems) {
      console.log('setting water systems')
      console.log(roles_water_systems)
      state.roles_water_systems = roles_water_systems
    },
    set_pws(state, pws) {
      if (state.user) {
        const user_id = state.user.user_id
        idb.set(user_id + '_pws', pws)
      }
      idb.set('pws_id', pws.pws_id)
      idb.set('pws_name', pws.pws_name)
      state.pws = pws
      state.pws_id = pws.pws_id
    },
    set_pws_id(state, pws_id) {
      idb.set('pws_id', pws_id)
      state.pws_id = pws_id
    },
    set_role(state, role) {
      state.role = role
    },
    set_switch_user(state, enabled) {
      state.switch_user = enabled
    },
    set_csrf_token(state, csrf_token) {
      state.csrf_token = csrf_token
    },
    set_signed_in_alert_shown(state, v) {
      state.signed_in_alert_shown = v
    },
  },
  getters: {
    pws_id: state => {
      return state.pws ? state.pws.pws_id : ''
    },
    pws_name: state => {
      return state.pws ? state.pws.pws_name : ''
    },
    water_systems: state => {
      console.log('Computing PWS')
      let water_systems = {}
      const ws = state.roles_water_systems
      console.log(ws)
      if (ws != null) {
        for (const role in ws) {
          console.log(role)
          const role_water_systems = ws[role]
          for (const ws_idx in role_water_systems) {
            const role_ws = role_water_systems[ws_idx]
            water_systems[role_ws.pws_id] = role_ws
          }
        }
      }
      return water_systems
    },
    default_pws: (state, getters) => {
      let ret = null
      if (state.pws != null) {
        ret = state.pws
      }
      else if (state.roles_water_systems != null ){
        // first entry
        ret = Object.values(getters.water_systems)[0]
      }
      else {
        ret = { pws_id: '0010002', pws_name: 'CITY OF NATCHEZ' }
      }
      return ret
    }
  },
})
